

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: ['item', 'multiple', 'groupId', 'index'],
    data() {
        return {
            isActive: false
        };
    },
    methods: {
        toggle(event) {
            if (this.multiple) this.isActive = !this.isActive;
            else {
                this.$parent.$children.forEach((item, index) => {
                    if (item.$el.id === event.currentTarget.parentElement.parentElement.id) item.isActive = !item.isActive;
                    else item.isActive = false;
                }); 
            }
        },

        startTransition(el) {
            el.style.height = el.scrollHeight + 'px';
        },

        endTransition(el) {
            el.style.height = '';
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class AccordionItem extends Vue {}

