

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: ['data'],
    methods: {
        isExternal(link) {
          if (link.startsWith('http://') || link.startsWith('https://')) {
              return true;
          } 
          return false;
        },
    },
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class TextBlock extends Vue {}

