

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: ['data']
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class QuoteBlock extends Vue {}

