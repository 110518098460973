

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import AccordionItem from '@part/elements/AccordionItem.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        AccordionItem
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            groupId: null
        };
    },
    mounted() {
        this.groupId = this.$el.id;
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class AccordionBlock extends Vue {}

